import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import API from '../api/api';

const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [tasks, setTasks] = useState(null);
    const [userStats, setUserStats] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const storedUser = JSON.parse(localStorage.getItem('user'));
    
    const clearBrowserCache = () => {
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach(name => {
            caches.delete(name);
          });
        });
      }
    };
  
    useEffect(() => {
      const fetchUserAndData = async (userInfo) => {
        const userId = "isvsde704222354";
        setIsLoading(true);
          
            try {
              // Fetch user data
              const userResponse = await axios.post('https://api.saphirestreamapp.com/api/login', { telegram_user_id: userId });
             
              // const userResponse = await axios.post('https://api.saphirestreamapp.com/api/login', 
              //   { 
              //     telegram_user_id: userInfo?.id,  
              //     username: userInfo?.username,
              //     first_name: userInfo?.first_name,
              //     last_name: userInfo?.last_name,
              //   }
              // );
              
              const newUser = userResponse.data;
              const token = userResponse.data.token;    
    
    
              const storedUserId = storedUser && storedUser.data.telegram_user_id;
    
              // if (storedUserId !== userId) {
              //   localStorage.clear();
              //   clearBrowserCache();
              //   window.location.reload(true);
              // }
  
              if (storedUserId !== newUser.id) {
                // localStorage.clear();
                clearBrowserCache();
                // window.location.reload(true);
              }
  
              localStorage.setItem('user', JSON.stringify(newUser));
              localStorage.setItem('profile', JSON.stringify({ access_token: token }));
              setUser(newUser);
    
    
              // Fetch tasks data
              const getTasksResponse = await API.get('/tasks');
              setTasks(getTasksResponse.data);
    
              // Fetch users stats data
              const getUserStatsResponse = await API.get('/admin_stats');
              setUserStats(getUserStatsResponse.data);
    
              setIsLoading(false);
            } catch (error) {
              console.error(error);
              setIsLoading(false);
            }
        
      };
  
  
      fetchUserAndData();
    }, []);
  
    // New useEffect to update token in local storage if it changes
    useEffect(() => {
      if (user && user.token) {
        const storedProfile = JSON.parse(localStorage.getItem('profile'));
        if (!storedProfile || storedProfile.access_token !== user.token) {
          localStorage.setItem('profile', JSON.stringify({ access_token: user.token }));
        }
      }
    }, [user]);
    
    const updateTasks = async () => {
      try {
        const taskResponse = await API.get('/tasks');
        setTasks(taskResponse.data)
        console.log(taskResponse.data);
      } catch (error) {
        console.error(error);
      }
    };
  
    const updateUsersStats = async () => {
      try {
        const statsResponse = await API.get('/admin_stats');
        setUserStats(statsResponse.data)
        console.log(statsResponse.data);
      } catch (error) {
        console.error(error);
      }
    };
  
    const updateUser = (updatedData) => {
      if (!user) {
        console.error("User is null");
        return;
      }
  
      const updatedUser = {
        ...user,
        data: {
          ...user.data,
          ...updatedData,
        },
      };
  
      setUser(updatedUser);
      localStorage.setItem('user', JSON.stringify(updatedUser));
    };  
      
    return (
      <AdminContext.Provider value={{ user, tasks, userStats, updateUsersStats, updateUser, updateTasks, isLoading }}>
        {children}
      </AdminContext.Provider>
    );
  };
  
  export default AdminContext;