import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Routes, Route, Navigate } from "react-router-dom";



import './main_section.css';
import EditTask from './Tasks/EditTask';
import ViewTasks from './Tasks/ViewTasks';
import Tasks from './Tasks/Tasks';
import AdminHome from './AdminHomepage/AdminHome';
import CMSPage from './CMS/CMSPage';
import AdminContext from '../../contexts/adminContext';


const MainSection = ({isOpen, toggleSidebar, handleBarClick}) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
    const { userStats, updateUsersStats } = useContext(AdminContext);
    console.log(userStats);

    const location = useLocation();
    const navigate = useNavigate();

    // useEffect(() => {
    //     dispatch(allBusinesses());
    //     dispatch(get_metrics());
    //     dispatch(allUsers());
    // }, [location])

    // const metrics = useSelector((state) => state.metrics);
    let metrics = userStats;
    

    const handleLogout = () => {
        navigate('/');
    };

  return (
    <main className={`admin_main-section ${isOpen ? 'sidebar-opened' : ''}`} onClick={handleBarClick}>
        <header className='bg-black p-4'>
            <nav className='container-fluid d-flex justify-content-between align-items-center gap-5 text-white'>
                <div><i onClick={toggleSidebar} role='button' className="bars-btn fa fa-bars fa-2x" aria-hidden="true"></i></div>
                <div className='auth d-flex align-items-center gap-2'>
                    {/* <span className='login rounded-pill px-1'>Admin Unit</span> */}
                    {user ? (
                        <span role='button' onClick={handleLogout} className='sign_up'>Sign Out</span>                
                    ) : (
                        <Link to="/" className='sign_up'>Login</Link>
                    )}
              </div>
            </nav>
        </header>


        <section className='admin-body p-3 py-5'>
            <Routes>
                <Route path="/" element={<AdminHome metrics={metrics} />} />
                <Route path="/tasks" element={<Tasks />} />
                <Route path="/tasks/details" element={<ViewTasks />} />
                <Route path="/tasks/edit/:id" element={<EditTask />} />
                <Route path="/tasks/cms" element={<CMSPage />} />
            </Routes>
        </section>

    </main>
  )
}

export default MainSection