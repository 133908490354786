import React from 'react';
import { Link, useLocation } from "react-router-dom";

import './sidebar.css';
import logo from '../../utils/images/sapphire coin.png';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  return (
    <aside className={`admin-aside bg-black ${isOpen ? 'sidebar-opened' : ''}`}>
        <section id="nav-tab" role="tablist" className='d-flex flex-column gap-3 px-3 text-white'>
            <span onClick={toggleSidebar}><i className="close-aside-btn fa fa-times-circle fa-2x" aria-hidden="true"></i></span>
            <Link to="/admin" className='bg-white rounded'><img src={logo} alt="" width="100%" height="100px" /></Link>

            <Link to="/admin" className={`nav-link d-flex align-items-center gap-3 mt-3 ${location.pathname === "/admin" ? "active" : ""}`}>
              <i className="fa fa-dashboard fa-2x" aria-hidden="true"></i>
              <span className="mx-2">Dashboard</span>
            </Link>
            <Link to="/admin/tasks" className={`nav-link d-flex align-items-center gap-3 mt-3 ${location.pathname === "/admin/tasks" || location.pathname === "/admin/tasks/details" ? "active" : ""}`}>
            <i className="fa-solid fa-list-check fa-2x"></i>
              <span className="mx-2">Tasks</span>
            </Link>
            {/* <Link to="/view_users" className={`nav-link d-flex align-items-center gap-3 mt-3 ${location.pathname === "/view_users" ? "active" : ""}`}>
              <i className="fa fa-users fa-2x" aria-hidden="true"></i>
              <span className="mx-2">Users</span>
            </Link> */}
            {/* <Link to="/cms" className={`nav-link d-flex align-items-center gap-3 mt-3 ${location.pathname === "/cms" ? "active" : ""}`}>
              <i className="fa-regular fa-newspaper fa-2x"></i>
              <span className="mx-2">CMS</span>
            </Link> */}
        </section>
    </aside>
  )
}

export default Sidebar