import React, { useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import API from '../../../api/api';
import AdminContext from '../../../contexts/adminContext';

const ViewTasks = () => {
  const { updateTasks } = useContext(AdminContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { userTask } = location.state;

  const [isEditing, setIsEditing] = useState(false);
  const [taskData, setTaskData] = useState(userTask);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await API.delete(`/tasks/${userTask.id}`);

      await updateTasks();

      setTimeout(() => {
        setIsDeleting(false);
        setMessage({ type: 'success', text: 'Task deleted successfully!' });
        setTimeout(() => {
          setMessage({ type: '', text: '' });
          navigate('/admin/tasks');
        }, 3000);
      }, 3000);
    } catch (error) {
      setIsDeleting(false);
      setMessage({ type: 'error', text: 'Failed to delete task. Please try again.' });
      setTimeout(() => setMessage({ type: '', text: '' }), 3000);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);

    if (!taskData.name || !taskData.type || !taskData.reward_in_coins || !taskData.data.description || !taskData.data.link) {
      setIsSaving(false);
      setMessage({ type: 'error', text: 'Please fill in all fields.' });
      setTimeout(() => setMessage({ type: '', text: '' }), 2000);
      return;
    }

    try {
      await API.put(`/tasks/${taskData.id}`, taskData);

      await updateTasks();
      setMessage({ type: 'success', text: 'Task saved successfully!' });
      setTimeout(() => {
        setIsSaving(false);
        setIsEditing(false);
        setTimeout(() => setMessage({ type: '', text: '' }), 3000);
      }, 3000);
    } catch (error) {
      setIsSaving(false);
      setMessage({ type: 'error', text: 'Failed to save task. Please try again.' });
      setTimeout(() => setMessage({ type: '', text: '' }), 3000);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTaskData({ ...taskData, [name]: value });
  };

  return (
    <section className='blogDetails-content container-fluid bg-white py-5 rounded'>
      <div className='d-flex'>
        <span role="button" onClick={() => navigate(-1)} className='rounded p-2'>
          <i className="fa fa-arrow-circle-left" aria-hidden="true"></i> Go back
        </span>
      </div>

      {message.text && (
        <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`} role="alert">
          {message.text}
        </div>
      )}

      {taskData ? (
        <section>
          <h3 className="text-center">Task Details</h3>

          <section className="userTask-profile container py-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="card shadow-sm">
                  <div className="card-header bg-transparent border-0 my-2">
                    <h3 className="mb-0">
                      <i className="far fa-clone pr-1"></i> General Information
                    </h3>
                  </div>
                  <div className="card-body pt-0">
                    <table className="table table-bordered">
                      <tbody>
                        <tr className='mb-4'>
                          <th className='ps-2' width="30%">Task Title</th>
                          <td width="2%">:</td>
                          <td>
                            {isEditing ? (
                              <input className='form-control' type="text" name="name" value={taskData?.name} 
                                onChange={handleChange}
                              />
                            ) : (
                              taskData?.name
                            )}
                          </td>
                        </tr>
                        <tr className='mb-4'>
                          <th className='ps-2' width="30%">Task ID</th>
                          <td width="2%">:</td>
                          <td>
                            {taskData?.id}
                          </td>
                        </tr>
                        <tr className='mb-4'>
                          <th className='ps-2' width="30%">Task Type</th>
                          <td width="2%">:</td>
                          <td>
                            {taskData?.type}
                          </td>
                        </tr>
                        <tr className='mb-4'>
                          <th className='ps-2' width="30%">Reward in coins</th>
                          <td width="2%">:</td>
                          <td>
                            {isEditing ? (
                              <input className='form-control' type="text" name="reward_in_coins" value={taskData?.reward_in_coins} 
                                onChange={handleChange}
                              />
                            ) : (
                              taskData?.reward_in_coins
                            )}
                          </td>
                        </tr>
                        {taskData?.data && (
                          <>
                            <tr className='mb-4'>
                              <th className='ps-2' width="30%">Description</th>
                              <td width="2%">:</td>
                              <td>
                                {isEditing ? (
                                  <textarea className='form-control' name="data.description" value={taskData?.data.description}
                                    onChange={(e) =>
                                      setTaskData({
                                        ...taskData,
                                        data: {
                                          ...taskData?.data,
                                          description: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                ) : (
                                  taskData?.data.description
                                )}
                              </td>
                            </tr>
                            <tr className='mb-4'>
                              <th className='ps-2' width="30%">URL Link</th>
                              <td width="2%">:</td>
                              <td>
                                {isEditing ? (
                                  <input className='form-control' type="text" name="data.link" value={taskData?.data.link} 
                                    onChange={(e) =>
                                      setTaskData({
                                        ...taskData,
                                        data: {
                                          ...taskData?.data,
                                          link: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                ) : (
                                  <Link to={taskData?.data.link}>{taskData?.data.link}</Link>
                                )}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                    <div className='d-flex justify-content-end gap-2'>
                      {isEditing ? (
                        <>
                          <button onClick={handleSave} className="btn btn-success">
                            {isSaving ? (<span className='spinner-border spinner-border-sm' role="status"></span>) :  (<span>Save</span>)}
                          </button>
                          <button onClick={handleCancel} className="btn btn-info text-white">
                            <span>Cancel</span>
                          </button>
                        </>
                      ) : (
                        <button onClick={() => setIsEditing(true)} className="btn btn-primary">
                          Edit
                        </button>
                      )}
                      <button onClick={handleDelete} className="btn btn-danger">
                        {isDeleting ? (<span className='spinner-border spinner-border-sm' role="status"></span>) :  (<span>Delete</span>)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      ) : (
        <div className="d-flex justify-content-center mt-3">
          <span className='spinner-border spinner-border-sm text-secondary' style={{ width: "3rem", height: "3rem" }} role="status"></span>
        </div>
      )}
    </section>
  );
};

export default ViewTasks;