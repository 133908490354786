import { useState } from "react";
import Sidebar from "../Sidebar_section/Sidebar";
import MainSection from "../Main_section/MainSection";


const AdminDashboard = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleBarClick = () => {
    if (isOpen) {
      toggleSidebar();
    }
  };

  return (
    <section className="admin_section">
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar}  />
      <MainSection isOpen={isOpen} toggleSidebar={toggleSidebar} handleBarClick={handleBarClick} />
    </section>
  );
}

export default AdminDashboard;
