import React, { useState, useContext } from 'react';
import AdminContext from '../../../contexts/adminContext';
import { useNavigate } from 'react-router-dom';

import taskIcon from '../../../utils/images/Task.png';
import coinIcon from '../../../utils/images/Tap coin.png';
import API from '../../../api/api';

const Tasks = () => {
  const { tasks, updateTasks } = useContext(AdminContext);
  const navigate = useNavigate();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [newTask, setNewTask] = useState({
    name: '',
    reward_in_coins: '',
    type: 'special',
    data: {
      description: '',
      link: '',
    }
  });
  const [isLoading, setIsLoading] = useState(false);
  const [deletingTaskId, setDeletingTaskId] = useState(null);
  const [message, setMessage] = useState({ type: '', text: '' });

  const getTasksByType = (type) => tasks?.data?.filter(task => task.type === type) || [];
  const specialTasks = getTasksByType('special');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'description' || name === 'link') {
      setNewTask((prevTask) => ({
        ...prevTask,
        data: {
          ...prevTask.data,
          [name]: value,
        },
      }));
    } else {
      setNewTask((prevTask) => ({
        ...prevTask,
        [name]: value,
      }));
    }
  };
  
  const handleAddTask = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!newTask?.name || !newTask?.reward_in_coins || !newTask?.data?.description || !newTask?.data?.link) {
      setMessage({ type: 'error', text: 'Please fill in all fields.' });
      setTimeout(() => setMessage({ type: '', text: '' }), 2000);
      return;
    }


    try {
      await API.post('/tasks', newTask);

      await updateTasks();
      setMessage({ type: 'success', text: 'Task added successfully!' });
      setTimeout(() => {
        setIsFormVisible(false);
        setMessage({ type: '', text: '' });
      }, 2000);
      setNewTask({ name: '', type: 'special', reward_in_coins: '', data: { description: '', link: '' } });
    } catch (error) {
      setMessage({ type: 'error', text: 'Failed to add task. Please try again.' });
    } finally {
      setIsLoading(false);
      setTimeout(() => setMessage({ type: '', text: '' }), 3000);
    }
  };

  const handleDelete = async (userTask) => {
    setDeletingTaskId(userTask.id);
    try {
      await API.delete(`/tasks/${userTask.id}`);

      await updateTasks();

      setTimeout(() => {
        setDeletingTaskId(null);
        setMessage({ type: 'success', text: 'Task deleted successfully!' });
        setTimeout(() => {
          setMessage({ type: '', text: '' });
          navigate('/admin/tasks');
        }, 3000);
      }, 3000);
    } catch (error) {
      setDeletingTaskId(null);
      setMessage({ type: 'error', text: 'Failed to delete task. Please try again.' });
      setTimeout(() => setMessage({ type: '', text: '' }), 3000);
    }
  };

  const goToTaskPage = (userTask) => {
    navigate('/admin/tasks/details', { state: { userTask } });
  };

  return (
    <section className='tasks-section'>
      <div className='d-flex align-items-center justify-content-center mb-3'>
        <img src={taskIcon} alt="taskIcon" className='img-fluid me-2' width="45px" height="45px" />
        <h2>Tasks</h2>
      </div>

      <div className="d-flex align-items-center justify-content-end">
        <button onClick={() => setIsFormVisible(!isFormVisible)} className="btn btn-primary mb-3">
          {isFormVisible ? 'Cancel' : 'Add Task'}
        </button>
      </div>

      {message.text && (
        <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`} role="alert">
          {message.text}
        </div>
      )}

      {isFormVisible && (
        <form onSubmit={handleAddTask} className='mb-3'>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Task Name</label>
            <input type="text" className="form-control" id="name" name="name" value={newTask?.name} onChange={handleInputChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="reward_in_coins" className="form-label">Reward in Coins</label>
            <input type="number" className="form-control" id="reward_in_coins" name="reward_in_coins" value={newTask?.reward_in_coins} onChange={handleInputChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">Description</label>
            <textarea className="form-control" id="description" name="description" value={newTask?.data?.description} onChange={handleInputChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="link" className="form-label">URL Link</label>
            <input type="url" className="form-control" id="link" name="link" value={newTask?.data?.link} onChange={handleInputChange} required />
          </div>
          <button type="submit" className="btn btn-success">
            {isLoading ? (<span className='spinner-border spinner-border-sm' role="status"></span>) :  (<span>Save</span>)}
          </button>
        </form>
      )}

      <table className="table table-striped text-white">
        <thead>
          <tr>
            <th></th>
            <th>Task Name</th>
            <th>Reward</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {specialTasks.length > 0 ? (
            specialTasks.map(task => (
              <tr key={task.id}>
                <td><div className='d-flex justify-content-center pt-1'><i className="fa-solid fa-circle-dot"></i></div></td>
                <td>{task.name}</td>
                <td>
                  <div className='d-flex align-items-center'>
                    <img src={coinIcon} alt="coin-icon" width="25px" />
                    <span className='ps-2 amount'>{task.reward_in_coins}</span>
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center gap-2'>
                    <button onClick={() => goToTaskPage(task)} className="btn btn-primary btn-sm me-2">View</button>
                    <button onClick={() => handleDelete(task)} className="btn btn-danger btn-sm">
                    {deletingTaskId === task.id ? (
                        <span className='spinner-border spinner-border-sm' role="status"></span>
                      ) : (
                        <span>Delete</span>
                      )}
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="fw-bold text-center" colSpan="4">No tasks available</td>
            </tr>
          )}
        </tbody>
      </table>
    </section>
  );
}

export default Tasks;