import React from 'react';

import '../main_section.css';


const AdminHome = ({metrics}) => {
  return (
    <section className='metrics-section'>
          <section className="row">
              <div className="col-md-4 col-sm-12 my-2">
                  <div className="metrics-card d-flex align-items-center px-3 bg-white rounded-3 gap-3">
                      <div className=''><i className="fa fa-users" aria-hidden="true"></i></div>
                      <div className=" d-flex flex-column gap-2">
                          <span>Total number of users</span>
                          <h3>{metrics?.total_users}</h3>
                      </div>
                  </div>
              </div>
              <div className="col-md-4 col-sm-12 my-2">
                  <div className="metrics-card d-flex align-items-center px-3 bg-white rounded-3 gap-3">
                      <div className=''><i className="fa-solid fa-users-viewfinder"></i></div>
                      <div className=" d-flex flex-column gap-2">
                          <span>Total number of active users</span>
                          <h3>{metrics?.total_active_users}</h3>
                      </div>
                  </div>
              </div>
              <div className="col-md-4 col-sm-12 my-2">
                  <div className="metrics-card d-flex align-items-center px-3 bg-white rounded-3 gap-3">
                      <div className=''><i className="fa-solid fa-users-slash"></i></div>
                      <div className=" d-flex flex-column gap-2">
                          <span>Total number of non-active users</span>
                          <h3>{metrics?.total_inactive_users}</h3>
                      </div>
                  </div>
              </div>
          </section>

    </section>
  )
}

export default AdminHome