import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './auth.css';
import authImg from '../../utils/images/sapphire coin.png';

const initialState = {
  admin_name: "",
  email: "",
  password: "",
  confirm_password: "",
};

const Auth = () => {
    const [isSignUp, setIsSignUp] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const [loading, setLoading] = useState();
    const [successMsg, setSuccessMsg] = useState(false);
    const [formError, setFormError] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();
    

    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
      setShowConfirmPassword(!showConfirmPassword);
    };

    const handleChange = (e) => {
        e.preventDefault();
        setFormError([]);
        setFormData({
          ...formData,
          [e.target.name]:
            e.target.type === "checkbox" ? e.target.checked : e.target.value,
        });
        // setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setFormError([]);
        let errors = [];

        if (isSignUp) {

            if (
              !formData.admin_name ||
              !formData.email ||
              !formData.password ||
              !formData.confirm_password
            ) {
              errors.push("Please fill in all form fields");
            }

            if (formData.password.length < 8) {
                errors.push("Password should be at least 8 character");
            }

            if (formData.password !== formData.confirm_password) {
              errors.push("Password does not match");
            }

            if (!emailRegex.test(formData.email)) {
                errors.push("Invalid email address");
            }

        } else {
            if (!formData.email || !formData.password) {
                errors.push("Please fill in all fields");
            }
            if (!emailRegex.test(formData.email)) {
                errors.push("Invalid email address");
            }
        }

        setFormError(errors);

        return errors.length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const defaultLoginData = {
            email: "admin@saphire.com",
            password: "nopassword"
        };
        
        const loginData = { email: formData.email, password: formData.password }

        const registrationData = {
            admin_name: formData.admin_name,
            email: formData.email,
            password: formData.password,
        };

        // Validate the form
        const isValid = validateForm();
        
        if (isValid) {
            setLoading(true);
            if (isSignUp) {
                // signup( registrationData, navigate, setFormData, setFormError, setLoading, setIsSignUp, setSuccessMsg);
            } else {
                // login(loginData, navigate, setFormData, setFormError, setLoading);
                setTimeout(() => {
                    setLoading(false);
                    if (
                      formData.email === defaultLoginData.email &&
                      formData.password === defaultLoginData.password
                    ) {
                      setSuccessMsg(true);
                      setTimeout(() => {
                        navigate("/admin");
                      }, 1000);
                    } else {
                      setFormError(["Invalid login credentials"]);
                    }
                  }, 3000);
            }
        } else {
            return
        }
    }


    if (successMsg) {
      setTimeout(() => {
        setSuccessMsg(false);
      }, [20000]);
    }
    if (formError) {
      setTimeout(() => {
        setFormError([]);
      }, [20000]);
    }

    return (
       <section>
        
        <nav className="navbar navbar-light bg-dark text-light">
            <div className="container py-2">
                Admin Login Page
            </div>
        </nav>
        <section className='auth-section-input row align-items-center container-fluid pt-3'>
            <div className="col-md-6 col-sm-12 image-container d-md-block d-none">
                <div className="d-flex align-items-center justify-content-center">
                    <img src={authImg} alt="img" className='img-fluid' />
                </div>
            </div>
            <div className="col-md-6 col-sm-12 p-md-4 p-sm-0 bg-white">
                <div className="d-flex justify-content-center gap-3">
                    <form onSubmit={handleSubmit}>
                        <div className='text-center fw-bold'>
                            {isSignUp ? (
                                <h3>Create an Account </h3>
                            ) : (
                                <h3>Log In to your Account </h3>
                            )}
                        </div>
                        
                        
                        <div className='flash-msgs'>
                            {successMsg && <div className='bg-success text-white rounded text-center my-1 p-1'>Login Request Successful</div>}
                            {formError.length > 0 && (<div className="text-white text-center my-2 px-4">
                                    <div className='bg-danger rounded my-1 p-1'>{formError[0]}</div>
                            </div>)}
                        </div>
                        {isSignUp && (
                            <div className="row mx-1">
                                <div className="mb-3 col-md-12 col-sm-12">
                                    <label htmlFor="last_name" className="form-label">Last Name</label>
                                    <input type="text" onChange={handleChange} className="form-control" name='last_name' id="last_name" required />
                                </div>
                            </div>
                        )}
                        <div className="mb-3 mx-2">
                            <label htmlFor="email" className="form-label">Email address</label>
                            <input type="email" onChange={handleChange} className="form-control" name='email' id="email" aria-describedby="emailHelp" required />
                        </div>
                        {isSignUp ? (
                            <div className="row mx-1">
                                <div className="mb-3 col-md-6 col-sm-12">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <div className="input-group">
                                        <input type={showPassword ? "text" : "password"} onChange={handleChange} className="form-control" name='password' id="password" required />
                                        <span className="input-group-append">
                                            <span onClick={toggleShowPassword} className="">
                                                {showPassword ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-regular fa-eye"></i>}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-3 col-md-6 col-sm-12">
                                    <label htmlFor="password2" className="form-label">Confirm Password</label>
                                    <div className="input-group">
                                        <input type={showConfirmPassword ? "text" : "password"} onChange={handleChange} className="form-control" name='confirm_password' id="confirm_password" required />
                                        <span className="input-group-append">
                                            <span onClick={toggleShowConfirmPassword} className="">
                                                {showConfirmPassword ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-regular fa-eye"></i>}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="mb-3 mx-2">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <div className="input-group">
                                        <input type={showPassword ? "text" : "password"} onChange={handleChange} className="form-control" name='password' id="password" required />
                                        <span className="input-group-append">
                                            <span onClick={toggleShowPassword} className="">
                                                {showPassword ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-regular fa-eye"></i>}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                {/* <div className="remember-forgot mx-2 mb-3 d-flex justify-content-between gap-3">
                                    <div className="d-flex gap-2 align-items-center">
                                        <input type="checkbox" onChange={handleChange} checked={formData.rememberUser} name="rememberUser" />
                                        <span>Remember me</span>
                                    </div>
                                    <Link to="/auth/password_recovery" >Forgot Password</Link>
                                </div> */}
                            </>
                        )}

                        <div className="d-flex mx-2">
                            <button type="submit" className="form-control btn bg-black text-white mt-3 py-2">
                                {loading ? 
                                    (
                                        <div className="d-flex justify-content-center"><span className='spinner-border spinner-border-sm text-info' role="status"></span></div>
                                    ):(
                                        <>{isSignUp ? 'Get Started' : 'Continue'}</>
                                    )}
                            
                            </button>
                        </div>
                    </form>
                </div>

                {/* <section className="d-flex align-items-center mx-2 my-3 gap-3 horizontal-rule"><span></span> Or <span></span></section>


                {isSignUp ? (
                    <div className="mt-5 text-center"><p>Already have an account? <span onClick={() => setIsSignUp(false)} className='reg-btn fw-bold'>Login Here</span></p></div>
                ) : (
                    <div className="mt-5 text-center"><p>New User? <span onClick={() => setIsSignUp(true)} className='reg-btn fw-bold'>Sign Up Here</span></p></div>
                )} */}

            </div>
        </section>

       </section>
    )
}

export default Auth;