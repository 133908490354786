import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom/dist";

import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import Auth from "./components/Auth/Auth";
import { AdminProvider } from "./contexts/adminContext";

function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const location = useLocation();
  useEffect(() => {
    const userProfile = localStorage.getItem("profile");
    if (userProfile) {
      setUser(JSON.parse(userProfile));
    }
  }, [location]); 

  // const isAdmin = user?.user.role === "admin";

  return (
    <AdminProvider>
      <section className="admin_section">
        <Routes>
            {/* <Route path="/admin/*" element={ isAdmin ? <Admin /> : <Navigate to="/" replace /> } /> */}
            <Route path="/admin/*" element={ <AdminDashboard user={user} setUser={setUser} /> } />
            <Route path="/" element={ <Auth user={user} setUser={setUser} /> } />
          </Routes>
      </section>
    </AdminProvider>
  );
}

export default App;
